<template>
  <div class="selling">
    <div class="btn-groups">
      <el-button size="mini" type="primary" @click="$router.push({name:'marketing.shop.goods.create'})">添加商品</el-button>
      <el-button size="mini" type="primary"
                 @click="$router.push({name: 'marketing.shop.classification',params:{uuid:''} })" plain>添加分类
      </el-button>
      <el-input style="width:200px; margin-left: 16px" size="mini" suffix-icon="el-icon-search"
                placeholder="请输入商品名称" v-model="searchParameter.name" @blur="handleSearch"></el-input>
    </div>
    <div class="filter-condition">
      <el-form :inline="true" size="mini">
        <el-form-item label="商品名称">
          <el-input
            v-model="searchParameter.name"
            style="width: 150px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品分类">
          <el-select

            style="width: 150px;"
            v-model="searchParameter.categoryUuid"
          >
            <el-option
              v-for="item in groups"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="库存扣减">
          <el-select style="width: 150px;" v-model.number="searchParameter.stockDeductMode">
            <el-option
              :label="stockDeductMode.label"
              :value="stockDeductMode.value"
              v-for="stockDeductMode in stockDeductModes"
            ></el-option>

          </el-select>
        </el-form-item>
        <el-form-item label="销量区间">
          <el-input
            v-model.number="searchParameter.minSales"
            style="width: 70px;"
            placeholder="最低"
          ></el-input>
          &nbsp;-&nbsp;
          <el-input
            v-model.number="searchParameter.maxSales"
            style="width: 70px;"
            placeholder="最高"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="价格区间"
          style="margin-right: 0;"
        >
          <el-input
            v-model="searchParameter.minPrice"
            placeholder="最低"
            @input="searchParameter.minPrice=searchParameter.minPrice.match(/^\d*(\.?\d{0,2})/g)[0]||null"
            style="width: 70px;"
          ></el-input>
          &nbsp;-&nbsp;
          <el-input
            v-model="searchParameter.maxPrice"
            placeholder="最高"
            @input="searchParameter.maxPrice=searchParameter.maxPrice.match(/^\d*(\.?\d{0,2})/g)[0]||null"
            style="width: 70px;"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="right">
        <el-button size="mini" type="primary" @click="handleSearch">筛选</el-button>
        <el-button size="mini" type="text" @click="handleSearchClear">清空筛选条件</el-button>
      </div>
    </div>
    <el-table
      class="idefine-table"
      :data="tableData"
      @sort-change="handleSortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        label="商品/价格"
        width="350"
      >
        <template slot-scope="scope">
          <div class="table-picture-text">
            <div class="avatar">
              <img
                :src="scope.row.thumb"
                alt=""
              >
            </div>
            <p
              class="text"
              @click="$router.push({path:`detail/${scope.row.uuid}`})"
            >{{scope.row.name}}</p>
            <span class="price">￥{{scope.row.minPrice}}</span>
            <el-popover
              placement="bottom"
              width="360"
              trigger="click"
              :ref="'popover-' + scope.row.uuid"
              :append-to-body="true"
            >
              <el-form
                :inline="true"
                class="small"
              >
                <el-form-item label="名称">
                  <el-input
                    v-model="scope.row.nameCopy"
                    style="width: 280px;"
                  ></el-input>
                </el-form-item>
                <el-form-item label="售价">
                  <el-input
                    :disabled="Boolean(scope.row.hasMultiSku)"
                    max="9999999"
                    @input="scope.row.minPriceCopy=scope.row.minPriceCopy.match(/^\d*(\.?\d{0,2})/g)[0]||null"
                    v-model="scope.row.minPriceCopy"
                    style="width: 60px;"
                  ></el-input>
                </el-form-item>
                <el-form-item label="库存">
                  <el-input
                    :disabled="Boolean(scope.row.hasMultiSku)"
                    v-model.number="scope.row.stockCopy"
                    style="width: 60px;"
                  ></el-input>
                </el-form-item>
                <p class="tip" v-if="scope.row.hasMultiSku">该商品为多规格商品，无法统一修改售价与库存</p>
                <el-form-item style="width: 100%;margin-bottom: 0;">
                  <div style="text-align: right;width: 335px;">
                    <el-button
                      type="default"
                      @click="handleCancelPopover(scope.row)"
                      plain
                    >取消
                    </el-button>
                    <el-button
                      type="primary"
                      @click="handleConfirmPopover(scope.row)"
                    >确定
                    </el-button>
                  </div>
                </el-form-item>
              </el-form>
              <el-button
                type="text"
                slot="reference"
                class="edit-btn-icon"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </el-popover>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        property="stock"
        label="库存"
      ></el-table-column>
      <el-table-column
        property="sales"
        label="销量"
      ></el-table-column>
      <el-table-column
        label="创建时间"
        width="160"
      >
        <template slot-scope="scope">
          {{scope.row.createdAt | formatDateTime}}
        </template>
      </el-table-column>
      <el-table-column
        property="sn"
        sortable="custom"
        label="编号"
      ></el-table-column>
      <el-table-column
        property="popularity"
        label="人气"
      ></el-table-column>
      <el-table-column
        label="操作"
        width="100"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="$router.push({path:`detail/${scope.row.uuid}`})"
          >编辑
          </el-button>
          <span class="divider">&nbsp;|&nbsp;</span>
          <el-button
            type="text"
            @click="handleCopyShop(scope.row.uuid)"
          >复制
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-operate">
      &nbsp;
      <el-popover
        placement="top"
        width="300px"
        v-model="outstock"
      >
        <p style="margin-bottom: 20px;">您确定将本页所勾选的商品下架吗？</p>
        <div style="text-align: right; margin: 0">
          <el-button
            size="mini"
            type="text"
            @click="outstock = false"
          >取消
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="handleConfirmDown"
          >确定
          </el-button>
        </div>
        <el-button slot="reference" size="mini">下架</el-button>
      </el-popover>
      &nbsp;
      <el-button @click="handleConfirmDel" size="mini">删除</el-button>
      <el-pagination
        size="mini"
        style="float: right;"
        :page-size="searchParameter.limit"
        @current-change="handleCurrentChange"
        @size-change="sizeChange"
        layout=" sizes, prev, pager, next, jumper,->,total"
        :total="totalNum"
        :current-page.sync="currentPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import {
    ItemCount,
    Items,
    ItemCategories,
    ItemCopy,
    ItemTakeDown,
    ItemDelete,
    ItemShortcutUpdate
  } from '@/api/marketing/shop/commodity'

  export default {
    components: {},
    data() {
      const tableData = []
      const ops = {
        bar: {
          size: '1px',
          background: '#26C6DA',
          opacity: 0.5
        },
        rail: {
          size: '0px'
        },
      }
      return {
        currentPage: 1,
        totalNum: 0,
        uuids: [],
        stockDeductModes: [
          {label: '全部', value: 0},
          {label: '下单扣减', value: 1},
          {label: '付款扣减', value: 2},
          {label: '出库扣减', value: 3},
        ],
        searchParameter: {
          type: 0,
          name: '',
          sn: '',
          categoryUuid: '',
          itemType: 0,
          stockDeductMode: 0,
          minSales: null,
          maxSales: null,
          minPrice: null,
          maxPrice: null,
          sortField: 'sn',
          sortAsc: true,
          start: 0,
          limit: 10
        },
        tableData,
        groups: [],
        ops,
        outstock: false,
        outstock2: false,
        basicVisible: false
      }
    },
    methods: {
      init() {
        let {minPrice, maxPrice} = this.searchParameter
        minPrice = minPrice * 100
        maxPrice = maxPrice * 100
        let dataParameter = {...this.searchParameter, minPrice, maxPrice}
        Items(dataParameter).then((res) => {
          res.data = res.data === null ? [] : res.data
          res.data.map(v => {
            v.minPrice = v.minPrice / 100
            v.minPriceCopy = v.minPrice
            v.nameCopy = v.name
            v.stockCopy = v.stock
            return v
          })
          this.tableData = res.data
          ItemCount(this.searchParameter).then((res) => {
            this.totalNum = res.data.total
          })
        })
        ItemCategories().then((res) => {
          this.groups = res.data
          this.groups.unshift({uuid: '', name: '全部'})
        })
      },
      handleSearch() {
        this.searchParameter.start = 0
        this.init()
      },
      handleSearchClear() {
        this.searchParameter = {
          type: 0,
          name: '',
          sn: '',
          categoryUuid: '',
          itemType: 0,
          stockDeductMode: 0,
          minSales: 0,
          maxSales: 0,
          minPrice: 0,
          maxPrice: 0,
          sortField: '',
          sortAsc: true,
          start: 0,
          limit: 10
        }
      },
      handleCopyShop(uuid) {
        ItemCopy(uuid).then((res) => {
          if (res.code === 0) {
            this.init()
            this.$message.success('复制成功')
          }
        })
      },
      handleSortChange(data) {
        this.searchParameter.sortAsc = data.order === 'ascending'
        this.init()
      },
      // 快速修改
      handleConfirmPopover(row) {
        ItemShortcutUpdate(row.uuid, row.nameCopy, row.minPriceCopy * 100, row.stockCopy).then((res) => {
          if (res.code === 0) {
            row.minPrice = row.minPriceCopy
            row.name = row.nameCopy
            row.stock = row.stockCopy
            this.$message.success('修改成功')
            this.$refs[`popover-` + row.uuid].doClose()
          }
        })
      },
      handleCancelPopover(row) {
        this.$refs[`popover-` + row.uuid].doClose()
      },
      // 下架
      handleConfirmDown() {
        ItemTakeDown(this.uuids).then((res) => {
          if (res.code === 0) {
            this.$message.success('下架成功')
            this.outstock = false
            this.init()
          }
        })
      },
      handleConfirmDel() {
        this.$confirm('您确定要将本页所勾选的产品删除吗？', '删除商品', {type: 'warning'}).then(() => {
          ItemDelete(this.uuids).then((res) => {
            if (res.code === 0) {
              this.$message.success('删除成功')
              this.init()
            }
          })
        }).catch(() => {

        })
      },
      handleArrSetting() {
        if (this.uuids.length > 0) {
          this.basicVisible = true
        } else {
          this.$confirm('请确定勾选本页产品', '删除商品', {type: 'warning'})
        }
      },
      handleSelectionChange(selection) {
        selection = selection.map(v => v.uuid)
        this.uuids = selection
      },
      handleCurrentChange(val) {
        this.searchParameter.start = this.searchParameter.limit * (val - 1)
        this.init()
        console.log(`当前页: ${val}`)
      },
      sizeChange(size) {
        this.searchParameter.limit = size
        this.searchParameter.start = 0
        this.currentPage = 1
        this.init()
      }
    },
    mounted() {
      // this.init()
    }
  }
</script>
<style lang="scss" scoped>
  .selling {
    .btn-groups {
      margin-bottom: $margin;
      @include clearfix;

      .right {
        float: right;
      }
    }

    .filter-condition {
      border-radius: $border-radius;
      padding: $padding;
      @include set_bg_color($background-color-light-2, $background-color-dark-1);
      padding-bottom: 0;
      margin-bottom: $margin;

      .el-form-item {
        margin-right: 60px;
        margin-bottom: $margin;
      }

      .right {
        text-align: right;
        padding-bottom: $padding;
      }
    }

    .pagination-operate {
      margin-top: $margin;
    }

    .avatar {
      width: 70px;
      height: 70px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }


</style>
