import request from '@/util/request'

// 商品分类列表
export function Categories () {
  return request.get('/api/v1/shop/manage/itemCategories')
}

// 规格列表
export function SkuProps () {
  return request('/api/v1/shop/manage/skuProperties')
}
export function SkuPropAdd ({ name }) {
  return request.post('/api/v1/shop/manage/skuProperty/add', { name })
}

// 规格值列表
export function SkuPropValues ({ propertyUuid }) {
  return request('/api/v1/shop/manage/skuPropertyValues', { params: { propertyUuid } })
}

export function SkuPropValueAdd ({ propertyUuid, text }) {
  return request.post('/api/v1/shop/manage/skuPropertyValue/add', { propertyUuid, text })
}

/**
 * 获取商品分类列表
 */
export function ItemCategories (obj) {
  return request({
    url: '/api/v1/shop/manage/itemCategories',
    method: 'GET',
    params: obj
  })
}

/**
 * 获取商品分类详情
 */
export function ItemCategory (uuid) {
  return request({
    url: '/api/v1/shop/manage/itemCategory',
    method: 'GET',
    params: { uuid }
  })
}

/**
 * 获取商品分类总数
 */
export function ItemCategoryCount (keyword) {
  return request({
    url: '/api/v1/shop/manage/itemCategory/count',
    method: 'GET',
    params: keyword
  })
}

/**
 * 更新商品分类
 */
export function ItemCategoryUpdate (uuid, obj) {
  return request({
    url: '/api/v1/shop/manage/itemCategory/update',
    method: 'POST',
    params: { uuid },
    data: obj
  })
}
/**
 * 更新商品分类序号
 */
export function ItemCategoryUpdateSort (uuid, sort) {
  return request({
    url: '/api/v1/shop/manage/itemCategory/updateSort',
    method: 'POST',
    params: { uuid },
    data: { sort }
  })
}
/**
 * 新增商品分类
 */
export function ItemCategoryAdd (obj) {
  return request({
    url: '/api/v1/shop/manage/itemCategory/add',
    method: 'POST',
    data: obj
  })
}

/**
 * 删除商品分类
 */
export function ItemCategoryDelete (uuid) {
  return request({
    url: '/api/v1/shop/manage/itemCategory/delete',
    method: 'GET',
    params: { uuid }
  })
}

/*
 * 新建商品
 */
export function ItemAdd (obj) {
  return request({
    url: '/api/v1/shop/manage/item/add',
    method: 'POST',
    data: obj
  })
}

/**
 * 更新商品
 */
export function ItemUpdate (uuid, obj) {
  return request({
    url: '/api/v1/shop/manage/item/update',
    method: 'POST',
    params: { uuid },
    data: obj
  })
}

/**
 * 复制商品
 */
export function ItemCopy (uuid) {
  return request({
    url: '/api/v1/shop/manage/item/copy',
    method: 'POST',
    params: { uuid }
  })
}

/**
 * 获取商品列表
 */
export function Items (keyword) {
  return request({
    url: '/api/v1/shop/manage/items',
    method: 'GET',
    params: keyword
  })
}

/**
 * 获取商品总数
 */
export function ItemCount (keyword) {
  return request({
    url: '/api/v1/shop/manage/item/count',
    method: 'GET',
    params: keyword
  })
}

/**
 * 获取商品详情
 */
export function Item (uuid) {
  return request({
    url: '/api/v1/shop/manage/item',
    method: 'GET',
    params: { uuid }
  })
}

/**
 * 批量更新商品
 */
export function ItemBatchUpdateByType (obj) {
  return request({
    url: '/api/v1/shop/manage/item/batchUpdateByType',
    method: 'POST',
    data: obj
  })
}

/**
 * 下架商品
 */
export function ItemTakeDown (uuids) {
  return request({
    url: '/api/v1/shop/manage/item/takeDown',
    method: 'POST',
    data: { uuids }
  })
}

/**
 * 上架商品
 */
export function ItemTakeUp (uuids) {
  return request({
    url: '/api/v1/shop/manage/item/takeUp',
    method: 'POST',
    data: { uuids }
  })
}

/**
 * 删除商品
 */
export function ItemDelete (uuids) {
  return request({
    url: '/api/v1/shop/manage/item/delete',
    method: 'POST',
    data: { uuids }
  })
}

// 加载上次sku数据
export function ItemLastSkus () {
  return request.get('/api/v1/shop/manage/item/lastSkus')
}

// 获取运费模板列表（选择器）
export function DeliveryExpressShortList () {
  return request.get('/api/v1/shop/manage/delivery/express/shortList')
}
/**
 * 快捷更新
 */
export function ItemShortcutUpdate (uuid, name, price, stock) {
  return request({
    url: '/api/v1/shop/manage/item/shortcutUpdate',
    method: 'POST',
    params: { uuid },
    data: { name, price, stock }
  })
}
// 获取新建商品所需数据
export function ItemCreate () {
  return request.get('/api/v1/shop/manage/item/create')
}
